.mb-btn {
  font-size: 15px;
  color: var(--primary-darker);
  background: transparent;
  height: 44px;
  line-height: 40px;
  border: 2px solid var(--primary-darker);
  text-align: center;
  padding: 0 30px;
  display: inline-block;
  font-weight: 600;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  text-transform: capitalize;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.mb-btn::after {
  position: absolute;
  content: '';
  z-index: -1;
  display: block;
  left: -20%;
  right: -20%;
  top: -4%;
  height: 150%;
  width: 150%;
  bottom: 0;
  -webkit-transform: skewX(45deg) scale(0, 1);
  -moz-transform: skewX(45deg) scale(0, 1);
  -ms-transform: skewX(45deg) scale(0, 1);
  transform: skewX(45deg) scale(0, 1);
  background: transparent;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.mb-btn:hover {
  color: var(--primary-darker);
  border-color: var(--primary-darker);
  box-shadow: 0 20px 60px 0 rgba(7, 36, 72, 0.4);
}
.mb-btn:hover::after {
  -webkit-transform: skewX(45deg) scale(1, 1);
  -moz-transform: skewX(45deg) scale(1, 1);
  -ms-transform: skewX(45deg) scale(1, 1);
  transform: skewX(45deg) scale(1, 1);
}

.mb-btn.simple {
  background: transparent;
}
.mb-btn.clean {
  background: transparent;
  border-color: transparent;
}
.mb-btn.link {
  background: transparent;
  border-color: transparent;
}
.mb-btn.link:hover {
  box-shadow: none;
  text-decoration: underline;
}
.mb-btn.solid {
  color: var(--primary-dark);
  background-color: var(--primary-darker);
  border-color: var(--primary-darker);
}
.mb-btn.solid:hover {
  color: var(--primary-darker);
  border-color: var(--primary-darker);
}
.mb-btn.solid::after {
  background-color: var(--primary-dark);
}
.mb-btn.simple:hover {
  color: var(--primary-dark);
  border-color: var(--primary-dark);
}
.mb-btn.simple::after {
  background-color: var(--primary-darker);
}
