@keyframes mail-animation-up {
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes mail-animation-right {
  0% {
    transform: translateX(-30px);
  }
  100% {
    transform: translateX(0px);
  }
}

.welcome,
.features,
.join,
.product,
.prices {
  @apply w-full relative px-4 md:px-0 mt-4 z-10;
  min-height: 100vh;
  max-width: var(--page-content-max-width);
}

/*.welcome, .features, .join {
  background-color: var(--primary-dark);
}

.product, .prices {
  background-color: var(--secondary-darker);
}*/

.mail-logo {
  right: 40px;
  bottom: 80px;
  width: 300px;
  height: 300px;
  position: absolute;
  animation: mail-animation-right 2s linear infinite alternate;
}

.mail {
  position: absolute;
  width: 30px;
  height: 30px;
  animation: mail-animation-up 2s linear infinite alternate;
}

.mail.m1 {
  bottom: 20px;
  left: 20px;
}

.mail.m2 {
  bottom: 120px;
  left: 220px;
}

.mail.m3 {
  bottom: 120px;
  right: 20px;
}

.mail.m4 {
  bottom: 220px;
  left: 80px;
}

.mail.m5 {
  bottom: 50px;
  right: 40px;
}

.mail.m6 {
  top: 40px;
  right: 80px;
}

@media (min-width: 768px) {
  .mail-logo {
    width: 400px;
    height: 400px;
    /*left: 200px;*/
  }
/*
  .mail.m1 {
    bottom: 20px;
    left: 20px;
  }

  .mail.m2 {
    bottom: 120px;
    left: 220px;
  }

  .mail.m3 {
    bottom: 120px;
    right: 220px;
  }

  .mail.m4 {
    bottom: 220px;
    left: 80px;
  }

  .mail.m5 {
    bottom: 50px;
    right: 120px;
  }

  .mail.m6 {
    top: 40px;
    right: 80px;
  }
}

@media (min-width: 1024px) {
  .mail-logo {
    left: 300px;
  }
}

@media (min-width: 1280px) {
  .mail-logo {
    left: 500px;
  }*/
}
