@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700&family=Noto+Sans+JP&display=swap');

html,
body {
  width: 100%;
  height: 100%;
  background-color: var(--background-color);
  color: var(--primary-darker);
}

#root {
  height: auto;
  min-height: 100vh;
  position: relative;
}

:root {
  --white: #eeeeee;
  --black: #000000;
  --background-color: #e2eaf3;
  --primary-darker: #072448;
  --secondary-darker: #ffcb00;
  --primary-dark: #54d2d2;
  --primary-dark-low-opacity: rgba(84, 210, 210, 0.5);
  --secondary-dark: #f8aa4b;
  --primary-lighter: #bfd1e5;
  --primary-lighter-low-opacity: rgba(191, 209, 229, 0.5);
  --secondary-lighter: #ff6150;
  --secondary-lighter-low-opacity: rgba(255, 97, 80, 0.5); /*rgba(241, 194, 187, 0.7); */

  --secondary-test: #d64933;
  --secondary-test2: #e79588;

  --header-height: 120px;
  --header-height-small: 100px;
  --footer-height: 60px;

  --page-content-max-width: 960px;
}

/* other global styles */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', 'Noto Sans JP', sans-serif;
  font-weight: bold;
}
