.header {
  height: var(--header-height);
  background-color: var(--primary-dark);
  transition: 0.4s;
  transition-timing-function: ease;
  z-index: 100;
  color: var(--white);
}

.header > nav {
  max-width: var(--page-content-max-width);
}

.header.sticky {
  height: var(--header-height-small);
  background-color: var(--primary-dark-low-opacity);
  border-bottom: 2px solid var(--primary-lighter-low-opacity);
}

.header .logo {
  border-color: var(--white);
}

.header.sticky .logo {
}

.header .logo svg {
  height: 75px;
  width: 75px;
  transition: 0.4s;
  transition-timing-function: ease;
}

.header.sticky .logo svg {
  height: 60px;
  width: 60px;
  transition: 0.4s;
  transition-timing-function: ease;
}

.header.sticky .mb-btn {
  color: var(--primary-dark);
}
