.card {
  min-height: 250px;
  background-color: var(--white);
  border-radius: 7px;
  position: relative;
}

.card:hover {
  box-shadow: 5px 5px 10px 1px rgba(28, 39, 53, 0.4);
}

.card::before {
  width: 100%;
  height: 20px;
  position: absolute;
  top: -4px;
  left: 0;
  content: ' ';
  border-radius: 8px;
  border-top: 4px solid transparent;
  animation: 5s ease;
}

.card:hover::before {
  border-top-color: var(--secondary-dark);
}
